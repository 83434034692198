<template>
  <div class="Map">
    <div id="map-container">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        container="map-parent"
        @load="onMapLoaded"
        :zoom="zoom"
        :center="center"
        :pitch="pitch"
        :bearing="bearing"
      >
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </div>
    <div v-if="mobileFlag" class="mobile-bar d-flex justify-content-around align-items-center">
      <div @click="changeToggle(index)" class="mobile-toggles" v-for="(toggle, index) in mobileToggles" :key="index">
        <div class="d-flex justify-content-center align-items-center">
          <mobileIcons :iconType="toggle.iconType" :active="toggle.active" />
        </div>
        <div class="subtitle">{{toggle.name}}</div>
      </div>
    </div>
    <transition name="slide">
      <div v-if="mobileToggles[0].active === true" >
        <sidebar  @setMapFilter="setMapFilter" @setActiveOption="setActiveOption" />
      </div>
    </transition>
    <!-- <transition name="slide">
      <div id="about-container" class="frosted-glass mobile" v-if="mobileFlag === true && aboutToggle === true">
        <aboutSection />
      </div>
    </transition>
    <transition name="slide">
      <div id="about-container" class="frosted-glass desktop" v-if="mobileFlag === false && aboutToggle === true">
        <b-btn class="about-close" @click="aboutClick">X</b-btn>
        <aboutSection />
      </div>
    </transition> -->
    <loading :loadingFlag="loadingFlag" />
  </div>
</template>

<script>
// IMPORT MAPBOX FUNCTIONS AND TEMPLATES
import Mapbox from 'mapbox-gl'

import {
  MglMap,
  MglNavigationControl,
  MglGeolocateControl
} from 'vue-mapbox'

// mapbox containers
var map = null

import { mapGetters } from 'vuex'

import sidebar from "@/components/sidebar.vue"

// import aboutSection from "@/components/about_section.vue";
import mobileIcons from "@/components/mobile_icons.vue";
import loading from "@/components/loading.vue";

/**
 * import THREEjs library
 */
const THREE = require('three')
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

const loader = new GLTFLoader();
let scene, camera, renderer = null

// parameters to ensure the model is georeferenced correctly on the map
let modelOrigin = [-73.959389, 40.666353];
let modelAltitude = 0;
let modelRotate = [Math.PI / 2, 0, 0];

let modelTransform = {}

import { buildingOptions, materials } from '@/assets/template/templates.js'

import { createLights } from '@/assets/utility/methods.js'

export default {
  name: 'Map',
  components: {
    MglMap,
    MglNavigationControl,
    MglGeolocateControl,
    sidebar,
    mobileIcons,
    loading
  },
  data(){
    return {
      accessToken: 'pk.eyJ1Ijoia3BmdWkiLCJhIjoiY2p6MWcxMXl4MDFlbTNsbDc1bnp6N3FjYSJ9.66qFOXwI661MOPOf7x96yA',
      mapStyle: 'mapbox://styles/kpfui/ckn9c157p02a618rzglws1mum',
      zoom: 16,
      center: [-73.9612906306404,40.66716018021481],
      loadingFlag: true,
      pitch: 60, // pitch in degrees
      bearing: 20, // bearing in degrees
      activeLayer: null,
      contextBBGFlag: true,
      mobileToggles:[
        {
          name: 'explore',
          iconType: 'tool',
          active: true
        }
      ]
    }
  },
  created(){
    this.mapbox = Mapbox

    camera = new THREE.Camera()
    scene = new THREE.Scene()

    createLights(scene)
  },
  mounted(){
    this.subscribeToStore()
  },
  destroyed(){
    this.unsubscribeFromStore()
  },
  computed:{
    ...mapGetters({
      'meshData': 'getMeshData',
      "mobileFlag": "getMobileFlag",
      'metric': 'getMetric',
      'setting': 'getSetting'
    })
  },
  methods:{
    changeToggle(index){

      const activeToggle = this.mobileToggles[index]

      if(activeToggle.active){
        activeToggle.active = !activeToggle.active 
      }else{
        this.mobileToggles.forEach((d)=> d.active = false)
        this.mobileToggles[index].active = true
      }
    },
    onMapLoaded(event){
      map = event.map

      this.createMeshDataSource();
      this.add3DContext();
    },
    setActiveOption (option) {

      this.loadingFlag = true;
      /**
       * handles removing current layer
       */
      if(this.activeLayer !== null) {
        let selectedObject = scene.getObjectByName(this.activeLayer);
        scene.remove(selectedObject)

        if (map.getLayer(option)) {
          map.removeLayer(option);
        }
      }

      this.activeLayer = option
      
      const sel = buildingOptions[option]
      this.add3dLayer(option, `../data/models/${sel.model}`, sel.material )
    },
    createMeshDataSource () {
      map.addSource('mesh-source-data', {
        type: 'geojson',
        data:  {
          'type': 'FeatureCollection',
          'features': [{
            'type': 'Feature',
            'properties': { 'name': 'Null Island' },
            'geometry': {
              'type': 'Point',
              'coordinates': [ 0, 0 ]
            }
          }]
        }
      })

      var layers = map.getStyle().layers;
 
      var labelLayerId;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
          labelLayerId = layers[i].id;
          break;
        }
      }

      map.addLayer({
        id: 'mesh-polygons',
        source: 'mesh-source-data',
        type:'fill',
        paint: {}
      }, labelLayerId)
    },
    resetMeshData (meshSourceData) {
      map.getSource('mesh-source-data').setData(meshSourceData)
    },
    addMeshDataToMap (meshSourceData) {

      this.loadingFlag = true;

      if (map.isSourceLoaded('mesh-source-data')) {
        map.getSource('mesh-source-data').setData(meshSourceData)
      }

      map.setPaintProperty('mesh-polygons', 'fill-color', this.metric[this.setting].mapFilter)

      map.setFilter('mesh-polygons', null)

      setTimeout(() => {
        this.loadingFlag = false
      }, 500);
    },
    setMapFilter (sliderValues, max, min) {

      const [from, to] = sliderValues

      if (to == max && from == min) {
        map.setFilter('mesh-polygons', ['all'])
      } else if (to == max) {
        map.setFilter('mesh-polygons', ['all', ['>=', 'values', from]]);
      } else {
        map.setFilter('mesh-polygons', ['all', ['<=', 'values', to]]);
      }
    },
    add3dLayer (layerName, location, material) {

      let vm = this

      map.addLayer({
        id: layerName,
        type: 'custom',
        renderingMode: '3d',
        onAdd: function (map, gl) {
          vm.load3DLayer(map, gl, layerName, location, material)
        },
        render: this.renderLayer
      }, 'waterway-label');
    },
    load3DLayer (map, gl, layerName, location, material) {

      loader.load(location, function (gltf) {

          gltf.scene.name = layerName

          gltf.scene.traverse((child) => {
            if (child.isMesh) child.material = material
          });

          scene.add(gltf.scene);
        }.bind(this)
      );

      // use the Mapbox GL JS map canvas for three.js
      if(renderer === null){
        renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true
        });
        
        renderer.autoClear = false;
      }

      this.loadingFlag = false;
    },
    renderLayer (gl, matrix) {
      let rotationX = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(1, 0, 0),
        modelTransform.rotateX
      );

      let rotationY = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 1, 0),
        modelTransform.rotateY
      );

      let rotationZ = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 0, 1),
        modelTransform.rotateZ
      );

      let m = new THREE.Matrix4().fromArray(matrix);
      let l = new THREE.Matrix4()

      .makeTranslation(
        modelTransform.translateX,
        modelTransform.translateY,
        modelTransform.translateZ
      )
      .scale(
        new THREE.Vector3(
        modelTransform.scale,
        -modelTransform.scale,
        modelTransform.scale
        )
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);

      camera.projectionMatrix = m.multiply(l);

      renderer.resetState();
      renderer.render(scene, camera);

      map.triggerRepaint();
    },
    /**
     * adds 3D context parameters
     */
    add3DContext () {

      let modelAsMercatorCoordinate = this.mapbox.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
      );

      // transformation parameters to position, rotate and scale the 3D model onto the map
      modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since our 3D model is in real world meters, a scale transform needs to be
        * applied since the CustomLayerInterface expects units in MercatorCoordinates.
        */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() 
      };

      this.add3dLayer('context','../data/models/context.gltf', materials.mat1)
      this.add3dLayer('context-bbg','../data/models/context_bbg.gltf', materials.mat1)
    },
    checkBBGContext (layer) {

      let contextBBGLayerName = 'context-bbg'
      let layers = ['DLI23', 'ShadM06']

      // add layer if not already on and it is not the  layer
      if((layers.includes(layer) ===  false) && this.contextBBGFlag === false){
        this.add3dLayer(contextBBGLayerName,'../data/models/context_bbg.gltf', materials.mat1)

        this.contextBBGFlag = true
      } else if ((layers.includes(layer) === true) && this.contextBBGFlag === true) {
        let selectedObject = scene.getObjectByName(contextBBGLayerName);
        scene.remove(selectedObject)

        if (map.getLayer(contextBBGLayerName)) {
          map.removeLayer(contextBBGLayerName);
        }

        this.contextBBGFlag = false
      }
    },
    subscribeToStore(){
      this.meshDataUnsubscriber = this.$store.subscribe((mutation) => {
        switch (mutation.type) {
          case 'setMeshData':

            if(this.meshData.features.length > 0) {
              this.addMeshDataToMap(this.meshData)
              this.checkBBGContext(this.metric.dataName)
            } else {
              this.resetMeshData(this.meshData)
            }

            break
        }
      })
    },
    unsubscribeFromStore(){
      this.meshDataUnsubscriber();
    },
  }
}
</script>

<style lang="scss">

/** vue slide up/down transition */
.slide-enter-active {
   transition-duration: 0.4s;
   transition-timing-function: ease-in;
}
.slide-leave-active {
   transition-duration: 0.3s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to, .slide-leave {
   max-height: 100vh;
   overflow: hidden;
}
.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}
/* end of vue transition*/



// MAP OPTIONS
#Map {
  width: 100vw;
  height: 100vh;
  overflow:hidden;
}
.mapboxgl-canvas {
  left: 0;
}

#map-container {
  position: absolute;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

  overflow: hidden;

  *:focus { outline: none; }
}
.mapboxgl-popup-content{
  // frosted glass effect
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(18,18,18,0.85);
  width: 375px;
}
.mapboxgl-popup-close-button{
  color: #ffffff;
}
.mapboxgl-ctrl-bottom-left{
  display: none;
}

.tooltip-column{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.tooltip-list{
  color: $off-white;
  flex-direction: column;
  left: 15px;
  position: relative;
  margin-top: 0.5rem;

  .tooltip-subitem{
    display: flex;


    h5{
      margin-left: 0.5rem;
    }
    h6{
      display: list-item;
      list-style-type: disc;
      list-style-position: inside; 
    }
  }
}

// ABOUT
.about-close{
  position: absolute;
  right: 1rem;
  top: 1rem;
}

#about-container{
  position: absolute;
  left: 0;

  background: $black;

  overflow-y: auto;

  top: 0px;
  width: 100%;

  &.mobile{
    height: calc(100vh - 80px);
  }
  &.desktop{
    height: 100vh;
  }
  

  z-index: 5;
}
</style>
