import {
  MeshStandardMaterial
} from 'three'

export const options = [
  {name: 'AOR', item: '0'},
  {name: 'Proposed', item: '1'}
  // {name: 'Alternate', item: '1A'},
  // {name: '17-Story', item: '1B'}
]

export const materials = {
  mat1: new MeshStandardMaterial( {color:0xffffff, roughness:1, metalness: 0.5} ),
  mat2: new MeshStandardMaterial( {color:0xc9c9c9, roughness:1, metalness: 0.5} ),
  mat3: new MeshStandardMaterial( {color:0x8b9aa1, roughness:1, metalness: 0.5} ),
  mat4: new MeshStandardMaterial( {color:0x4391cc, roughness:1, metalness: 0.5} )
}

export const buildingOptions = {
  "0": {
    material: materials.mat4,
    model: "0_geometry.gltf"
  },
  "1": {
    material: materials.mat4,
    model: "1_geometry.gltf"
  },
  "1A": {
    material: materials.mat4,
    model: "1A_geometry.gltf"
  },
  "1B" :{
    material: materials.mat4,
    model: "1B_geometry.gltf" 
  }
}

export const models = [
  {
    "dataName": "DLAvg",
    "toggle": false,
    "label": "Daylight Hours in Winter",
    description: "Average daily hours in Winter with sufficient daylight outdoors (10,000 lx). From Nov 07 to Feb 06.",
    "option": true,
    "results_settings": {
      "max": 5,
      "min" :0,
      "interval": 1,
      "values": [0,5],
      "marks": {
        0: '0 hr/day',
        5: '5 hr/day'
      },
      "gradient": ['#3d3d3d', '#7da55e', '#8ee8ab', '#f7eb57', '#feaf46'],
      mapFilter: [
        'interpolate',
        ['linear'],
        ['number', ['get', 'values']],
        0,'#3d3d3d',
        2, '#7da55e',
        3, '#8ee8ab',
        5, '#f7eb57'
      ]
    },
    "change_settings": {
      "interval": 0.1,
      "max": 0,
      "min" :-1.5,
      "values": [0,-1.5],
      "marks": {
        "-1.5": "-1.5 hr/day",
        0: "0 hr/day"
      },
      "gradient": ['#000000', '#595959', '#9ab6ff', '#e3e3e3'],
      mapFilter:  [
        "step",
        ["number", ["get", "values"]],
        "#000000", -1.5,
        "#595959",-1.0,
        "#9ab6ff",-0.6,
        "#e3e3e3"
      ] 
    },
    "DLAvg": {
      "0": {
        "change": "BBG_DLAvg_0_change.json",
        "results": "BBG_DLAvg_0_results.json",
      },
      "1": {
        "change": "BBG_DLAvg_1_change.json",
        "results": "BBG_DLAvg_1_results.json",
      },
      "1A": {
        "change": "BBG_DLAvg_1A_change.json",
        "results": "BBG_DLAvg_1A_results.json",
      },
      "1B": {
        "change": "BBG_DLAvg_1B_change.json",
        "results": "BBG_DLAvg_1B_results.json",
      }
    }
  },
  {
    "dataName": "DLI23",
    "toggle": false,
    "label": "Daily Light Integral",
    description: "Days in year that meet Daily Light Integral (DLI) of 23.0 or higher.",
    "option": true,
    "results_settings": {
      "max": 0.35,
      "min" :0.20,
      "interval": 0.1,
      "values": [0.20,0.35],
      "marks": {
        0.20: '20% days',
        0.35: '35% days'
      },
      "gradient": ['#78f0a4', '#ffff01', '#e97422', '#f62b00'],
      mapFilter: [
        'interpolate',
        ['linear'],
        ['number', ['get', 'values']],
        0.20, '#78f0a4',
        0.25, '#ffff01',
        0.30, '#e97422',
        0.35, '#f62b00'
      ]
    },
    "change_settings": {
      "interval": 0.02,
      "max": 0,
      "min" :-0.08,
      "values": [-0.08, 0],
      "marks": {
        "-0.08": "-29  days/yr",
        "0": "0 days/yr"
      },
      "gradient": ['#bd0000', '#bd3dff', '#0048ff', '#9ab6ff', '#e3e3e3'],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#bd0000", -0.08,
        "#bd3dff",-0.06,
        "#0048ff", -0.04,
        "#9ab6ff", -0.02,
        "#e3e3e3"
      ] 
    },
    "DLI23": {
      "0": {
        "change": "BBG_DLI23_0_change.json",
        "results": "BBG_DLI23_0_results.json",
      },
      "1": {
        "change": "BBG_DLI23_1_change.json",
        "results": "BBG_DLI23_1_results.json",
      },
      "1A": {
        "change": "BBG_DLI23_1A_change.json",
        "results": "BBG_DLI23_1A_results.json",
      },
      "1B": {
        "change": "BBG_DLI23_1B_change.json",
        "results": "BBG_DLI23_1B_results.json",
      }
    }
  },
  {
    "dataName": "DSTrees",
    "toggle": false,
    "label": "Sunlight Weeks in Growing Season",
    description: "The number of weeks in the growing season (Apr1-Sep30) where trees receive at least 6 hours of sunlight per day. ",
    "option": true,
    "results_settings": {
      "max": 24,
      "min" :0,
      "interval": 1,
      "values": [0,24],
      "marks": {
        0: '0 weeks',
        24: '24 weeks'
      },
      "gradient": ['#290099', '#358BBD', '#00BD6E', '#C6F0A3'],
      mapFilter: [
        'interpolate',
        ['linear'],
        ['number', ['get', 'values']],
        0,'#290099',
        6, '#358bbd',
        12, '#00BD6E',
        24, '#c6f0a3'
      ],
    },
    "change_settings": {
      "max": 0,
      "min": -9,
      "interval": 1,
      "values": [-9,0],
      "marks": {
        '-9': '-9 weeks',
        0: '0 weeks'
      },
      "gradient": ['#c52de2', '#d51f18', '#d84c00', '#fefd2d', '#ffffff'],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#c52de2", -9,
        "#d51f18",-6,
        "#d84c00",-3,
        "#fefd2d", -1,
        '#ffffff'
      ]
      ,
    },
    "DSTrees": {
      "0": {
        "change": "BBG_DSTrees_0_change.json",
        "results": "BBG_DSTrees_0_results.json",
      },
      "1": {
        "change": "BBG_DSTrees_1_change.json",
        "results": "BBG_DSTrees_1_results.json",
      },
      "1A": {
        "change": "BBG_DSTrees_1A_change.json",
        "results": "BBG_DSTrees_1A_results.json",
      },
      "1B": {
        "change": "BBG_DSTrees_1B_change.json",
        "results": "BBG_DSTrees_1B_results.json",
      }
    }
  },
  {
    "dataName": "SumAvg",
    "toggle": false,
    "label": "Harmful Solar Hours",
    description: "Description: Average daily hours of thermally harmful direct sun in warm months (April-October).",
    "option": true,
    "results_settings": {
      "max": 6,
      "min" :0,
      "interval": 1,
      "values": [0,6],
      "marks": {
        0: '0 hr/day',
        6: '6 hr/day'
      },
      "gradient": ['#78f0a4', '#ffff01', '#e97422', '#f62b00'],
      mapFilter: [
        'interpolate',
        ['linear'],
        ['number', ['get', 'values']],
        0, '#78f0a4',
        2, '#ffff01',
        4, '#e97422',
        6, '#f62b00'
      ]
    },
    "change_settings": {
      "interval": 1.1,
      "max": 0,
      "min" :-1.5,
      "values": [0,-1.5],	    
      // "marks": [0,-1.5], 	// marked as negative to show as decrease
      "marks": {
        "0": "0 hr/day",
        "1.5": "-1.5 hr/day"
      },
      "gradient": ['#7759ff', '#38bc0d', '#84ffb2', '#e3e5e9'],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#7759ff", -1.5,
        "#38bc0d",-0.3,
        "#84ffb2",0,
        "#e3e5e9"
      ]  
    },
    "SumAvg": {
      "0": {
        "change": "BBG_SumAvg_0_change.json",
        "results": "BBG_SumAvg_0_results.json",
      },
      "1": {
        "change": "BBG_SumAvg_1_change.json",
        "results": "BBG_SumAvg_1_results.json",
      },
      "1A": {
        "change": "BBG_SumAvg_1A_change.json",
        "results": "BBG_SumAvg_1A_results.json",
      },
      "1B": {
        "change": "BBG_SumAvg_1B_change.json",
        "results": "BBG_SumAvg_1B_results.json",
      }
    }
  },
  {
    "dataName": "WinAvg",
    "toggle": false,
    "label": "Beneficial Solar Hours",
    description: "Description: Average daily hours of thermally beneficial direct sun in cold months (October-April).",
    "option": true,
    "results_settings": {
      "max": 6,
      "min" :0,
      "interval": 1,
      "values": [0,6],
      "marks": {
        0: '0 hr/day',
        6: '6 hr/day'
      },
      "gradient": ['#1b6bff', '#97ffbd', '#feff7d', '#fe9941'],
      mapFilter: [
        'interpolate',
        ['linear'],
        ['number', ['get', 'values']],
        0, '#1b6bff',
        2, '#97ffbd',
        4, '#feff7d',
        6, '#fe9941'
      ]
    },
    "change_settings": {
      "interval": 0.5,
      "max": 0,
      "min" :-1.5,
      "values": [-1.5,0],
      "marks": {
        "-1.5": "-1.5 hr/day",
        "0": "0 hr/day"
      },	
      "gradient": ['#9e3dff', '#0048ff', '#9ab6ff', '#e3e3e3'],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#9e3dff", -1.5,
        "#0048ff",-0.3,
        "#9ab6ff",0,
        "#e3e3e3"
      ]
    },
    "WinAvg": {
      "0": {
        "change": "BBG_WinAvg_0_change.json",
        "results": "BBG_WinAvg_0_results.json",
      },
      "1": {
        "change": "BBG_WinAvg_1_change.json",
        "results": "BBG_WinAvg_1_results.json",
      },
      "1A": {
        "change": "BBG_WinAvg_1A_change.json",
        "results": "BBG_WinAvg_1A_results.json",
      },
      "1B": {
        "change": "BBG_WinAvg_1B_change.json",
        "results": "BBG_WinAvg_1B_results.json",
      }
    }
  },
  {
    "dataName": "WinAvgFeb",
    "toggle": false,
    "label": "Beneficial Solar Hours February",
    description: "Average daily hours of thermally beneficial direct sun in February. ",
    "option": true,
    "results_settings": {
      "max": 6,
      "min" :0,
      "values": [0,6],
      "marks": {
        0: '0 hr/day',
        6: '6 hr/day'
      },
      "gradient": ['#1b6bff', '#97ffbd', '#feff7d', '#fe9941'],
      mapFilter: [
        'interpolate',
        ['linear'],
        ['number', ['get', 'values']],
        0, '#1b6bff', 		// x < 2
        2, '#97ffbd', 		// 2 <= x < 4
        4, '#feff7d', 		// 4 <= x < 6
        6, '#fe9941'		// 6 <= x
      ]
    },
    "change_settings": {
      "interval": 0.5,
      "max": 0,
      "min" :-2.5,
      "values": [-2.5,0],
      "marks": {
        "-2.5": "-2.5 hr/day",
        "0": "0 hr/day"
      },	
      "gradient": ['#DC143C', '#FF00FF', '#9e3dff', '#0048ff', '#9ab6ff', '#e3e3e3'],
      mapFilter: [
        "step",
        ["number", ["get", "values"]],
        "#DC143C", -2.5,
        "#FF00FF",-2.0,
        "#9e3dff",-1.5,
        "#0048ff" ,-1.0,
        '#9ab6ff', -0.5,
        '#e3e3e3'
      ]
    },
    "WinAvgFeb": {
      "0": {
        "change": "BBG_WinAvgFeb_0_change.json",
        "results": "BBG_WinAvgFeb_0_results.json"
      },
      "1": {
        "change": "BBG_WinAvgFeb_1_change.json",
        "results": "BBG_WinAvgFeb_1_results.json"
      },
      "1A": {
        "change": "BBG_WinAvgFeb_1A_change.json",
        "results": "BBG_WinAvgFeb_1A_results.json"
      },
      "1B": {
        "change": "BBG_WinAvgFeb_1B_change.json",
        "results": "BBG_WinAvgFeb_1B_results.json"
      }
    }
  },
  {
    "dataName": "ShadM06",
    "toggle": false,
    "label": "Shadow Duration Increase, May 06",
    description: "Additional hours in shadow due to massing on given day.",
    "option": true,
    "results_settings": {
      "max": 3,
      "min" :0,
      "values": [0,3],
      "marks": {
        0: "0 hr",
        3: "3 h"
      },
      "gradient": ['#e3e3e3', '#9ab6ff', '#0048ff', '#9e3dff', '#FF00FF', '#DC143C', '#000000'],
      mapFilter: [
        'interpolate',
        ['linear'],
        ['number', ['get', 'values']],
        0, '#e3e3e3',		// x < 0.5
        0.5, '#9ab6ff',	// 0.5 <= x < 1.0
        1.0, '#0048ff', 	// 1.0 <= x < 1.5
        1.5, '#9e3dff', 	// 1.5 <= x < 2.0
        2.0, '#FF00FF', 	// 2.0 <= x < 2.5
        2.5, '#DC143C', 	// 2.5 <= x < 3.0
        3.0,'#000000'	// 3.0 <= x
      ]
    },
    "ShadM06": {
      "0": {
        "change": "BBG_ShadM06_0_change.json",
        "results": "BBG_ShadM06_0_results.json"
      },
      "1": {
        "change": "BBG_ShadM06_1_change.json",
        "results": "BBG_ShadM06_1_results.json"
      },
      "1A": {
        "change": "BBG_ShadM06_1A_change.json",
        "results": "BBG_ShadM06_1A_results.json"
      },
      "1B": {
        "change": "BBG_ShadM06_1B_change.json",
        "results": "BBG_ShadM06_1B_results.json"
      }
    }
  }
]