import Vue from 'vue';
import Vuex from 'vuex';
import { json } from 'd3-fetch';

// import { interpolateHcl } from "d3-interpolate"
// import { scaleLinear } from 'd3-scale';
// import axios from 'axios';

import {
  version
} from '../package.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mobileFlag: false,
    meshData:[],
    metric: null,
    version: version || '0',
    setting: 'results_settings'
  },
  getters :{
    getMetric(state) {
      return state.metric
    },
    getMobileFlag(state){
      return state.mobileFlag
    },
    getMeshData(state){
      return state.meshData
    },
    getVersion(state) {
      return state.version
    },
    getSetting(state) {
      return state.setting
    },
  },
  mutations: {
    setMetric(state, metric){
      state.metric = metric
    },
    setMobileFlag(state, newMobileFlag){
      state.mobileFlag = newMobileFlag
    },
    setMeshData(state, newMeshData){
      state.meshData = newMeshData
    },
    resetMeshData(state, meshData) {
      state.meshData = meshData
    },
    setSetting(state, setting) {
      state.setting = setting
    }
  },
  actions: {
    readMeshData(context, payload){
      if(payload !== null) {

        json('./data/analysis_layers/'+payload, ()=>{}).then((response)=>{
          context.commit('setMeshData', response)
        })

      } else {
        context.commit('setMeshData', {
          "type": "FeatureCollection",
          "crs": {
            "type": "name",
            "properties": {
              "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
            }
          },
          "features": []
        })
      }
    }
  }
})