import {
  DirectionalLight,
  AmbientLight
} from 'three'

export function createLights (scene) {

  const light = new AmbientLight(0xe8ecff, 0.4) // soft white light
  scene.add( light );
  // create two three.js lights to illuminate the model
  const directionalLight = new DirectionalLight(0xfff1f1, 0.8)
  directionalLight.position.set(1, 1, 1).normalize()
  scene.add(directionalLight);
  
  const directionalLight2 = new DirectionalLight(0x8fbfb9, 0.4)
  directionalLight2.position.set(-10, 0, 0).normalize()
  scene.add(directionalLight2);
}

export function flyTo (map, coords, zoom) {
  map.flyTo({
    center: [coords[0], coords[1]],
    zoom: zoom,
    speed: 1,
    curve: 1,
    easing (t) {
      return t
    }
  })
}