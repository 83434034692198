<template>
  <div id="sidebar">
    <div id="sidebar-container" class="text-left frosted-glass" :class="{'mobile': mobileFlag, 'desktop': mobileFlag == false}">
      <div class="container p-4">
        <div class="row" v-if="mobileFlag === false">
          <div class="col-12 d-inline-flex flex-column">
            <h1>Daylight Analysis</h1>
            <h6>960 Franklin Ave</h6>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div v-if="mobileFlag === true" class="d-flex">
              <h1>Shadow in the Park</h1><small style="color:lightgrey;">beta</small>
            </div>
            <p class="description-text text-align-justify">
              The Brooklyn Botanic Gardens and other community groups have expressed concern over several proposed developments at 960 Franklin Avenue. Citing the developments’ height and bulk, these stakeholders worry that significant amounts of daylight will be blocked to the Garden’s greenhouses, a neighborhood park, and the campus of Medgar Evers College.
            </p>

            <div class="row">
              <div class="col-12 d-flex">
                <h4>How to Read the Map</h4>
                <div class="ml-2" @click="toggleInfo = !toggleInfo"><arrowDown :toggleInfo="toggleInfo" /></div>
              </div>
            </div>
            <div class="" v-if="toggleInfo">
              <p class="description-text text-align-justify">
                Toggle between a hypotethic “As-of-Right" (AOR) building and the three proposed building options - “Proposed”, “Alternate”, and “17-Story” to populate the map with that model.
              </p>
              <p class="description-text text-align-justify">
                Then, switch on one of the analysis layers. You will see an option to display the analysis results as well as the change in results between the AOR building and the building option you currently have selected.
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 justify-content-around d-flex">
            <b-form-radio-group
              v-model="activeOption"
              :options="options"
              class="mb-3"
              value-field="item"
              button-variant="outline-info"
              text-field="name"
              disabled-field="notEnabled"
              buttons
              @change="updateLayer"
            ></b-form-radio-group>
          </div>
        </div>
        <hr>
        <div class="row mb-4" v-for="(metric, i) in models" :key="i">
          <div class="col-12">
            <switch-button
              v-model="metric.toggle"
              :dataName="metric.dataName"
              class="standard-text label"
              @toggle="selectMetric(metric)"
              :title="'adjust ' + metric.label"
              >{{ metric.label }}
            </switch-button>

            <div class="expand" v-if="metric.toggle">
              <p class="mt-2 description-text">
                {{metric.description}}
              </p>

              <div class="metric-options ml-4 mt-2" v-if="metric.toggle">
                <b-form-checkbox
                  v-if="metric['results_settings']"
                  v-model="metric.option"
                  :name="'id-results-'+metric.dataName"
                  :value="true"
                  @change="selectMetric(metric)"
                >
                  Results
                </b-form-checkbox>
                <b-form-checkbox
                  v-if="metric['change_settings']"
                  v-model="metric.option"
                  :name="'id-aor-'+metric.dataName"
                  :value="false"
                  @change="selectMetric(metric)"
                >
                  Change from AOR
                </b-form-checkbox>

                <div class="slider-container m-3">
                  <vue-slider :id="metric.dataName + '-slider'" :ref="metric.dataName + '-slider'"
                  v-model="metric[setting].values"
                  :enable-cross="false"
                  :process="false"
                  :marks="metric[setting].marks"
                  :max="metric[setting].max"
                  :min="metric[setting].min"
                  :interval="metric[setting].interval"
                  @drag-end="updateSlider(metric)"
                  @error="sliderError"
                  :style="{'background': 'linear-gradient(to right,' + metric[setting].gradient.toString() +')'}"
                  ></vue-slider>
                </div>
              </div>
            </div>


          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-12 text-center">
            <div class="anchor-highlight" @click="downloadPDF" title="download methodologies">download project methodology</div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <small class='text-muted'>
              beta v. {{version}}
            </small>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="mobileFlag" class="mobile-bar d-flex justify-content-around align-items-center">
      <div class="mobile-toggles">
        <div class="d-flex justify-content-center align-items-center">
          <mobileIcons :iconType="'tool'" :active="sidebarOpen" />
        </div>
        <div class="subtitle">{{toggle.name}}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// vue slider import
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

// switch button template
import switchButton from '@/components/switchButton.vue'

import {models, options} from '@/assets/template/templates.js'

import arrowDown from '@/components/arrow_down.vue';

export default {
  name:'sidebar',
  components:{
    switchButton,VueSlider, arrowDown
  },
  props:{},
  data(){
    return {
      sidebarOpen: true,
      options: options,
      models: models,
      activeOption: null,
      activeModel: null,
      toggleInfo: false
    }
  },
  computed:{
    ...mapGetters({
      'mobileFlag': "getMobileFlag",
      'version': 'getVersion',
      'setting': 'getSetting'
    }),
  },
  mounted () {},
  methods:{
    downloadPDF () {
      window.open('./data/files/960Franklin_Methodology.pdf', '_blank');
    },
    updateSlider (metric) {
      this.$emit('setMapFilter', metric[this.setting].values, metric[this.setting].max, metric[this.setting].min)
    },
    updateLayer () {
      let layer = null

      if(this.activeOption !== null) {
        this.$emit('setActiveOption', this.activeOption)
      }

      if(this.activeOption !== null && this.activeModel !== null) {
        let key = this.activeModel.dataName
        let tier = this.activeModel.option === true ? 'results' : 'change'

        this.$store.commit('setSetting', `${tier}_settings`)
        layer = this.activeModel[key][this.activeOption][tier]

        this.$store.dispatch('readMeshData', layer)
      } else {
        this.$store.dispatch('readMeshData', null)
      }

    },
    selectMetric(metric) {

      this.activeModel = null
      // turn off all other modells except active
      this.models.forEach((d) => {
        if(d.dataName !== metric.dataName){
          d.toggle = false
        } else if(d.dataName === metric.dataName && d.toggle === true){

          this.$store.commit('setMetric', d)
          this.activeModel = d
        } else{
          this.activeModel = null
        }
      })

      this.updateLayer()
    },
    sliderError(err) {
      if(err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss">
$legend-size: 20px;

.spacer-sm {
  height: 4rem;
}

.btn-group {
  width: 100%;

  .btn {
    margin: 0 0.5rem;
  }
}
.vue-slider-rail {
  background: transparent!important;
}
.anchor-highlight{
  color: #007bff;

  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}
.open-street-line{
  height: 5px;
  width: 75px;

  background: #3ad3ad;
}
ul{
  list-style: none;
}
.color-bubble{
  height: 15px;
  width: 15px;

  border-radius: 50%;

  display: inline;

  margin-right: 5px;
}
.mobile-bar{
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: $black;

  padding: 1rem;

  z-index: 10;

  border-top: 1px solid $dark-grey;

  pointer-events: none;
}

// SIDEBAR
#sidebar-container{
  position: absolute;
  left: 0;

  // background: $black;
  background: transparent;
  overflow-y: auto;

  &.mobile{
    bottom: 80px;
    width: 100%;
    height: calc(50vh - 80px);
  }
  &.desktop{
    top: 0px;
    width: 525px;
    height: 100vh;
  }
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
// SLIDER OPTIONS
.vue-slider-mark{
  background-color:#fdcbcc;
  border-radius: 50%;
  font-family: 'Roboto Mono', monospace;
  opacity:0.5;
  line-height: 1.33;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.45px;
}
.vue-slider-mark-label{
  font-family: 'Roboto Mono', monospace;
}

.metric-gradient-parent{
  padding: 0 15px;
}
.metric-gradient{
  border-radius: 5px;
  height: 15px;
}
</style>
